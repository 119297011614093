<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="event-page-wrap" v-if="event._id">
      <img :src="event.cover.imageURL" class="event-main-img" />
      <div class="event">
        <div class="event-txt">


          <div v-if="isPopupOpen && event.marketingPopupTitle" dir="ltr" :key="123456" class="popup">
            <div class="popup-content">
              <div @click="closePopup" class="close-icon"></div>
              <div class="popup-wrapper">
                <div class="form-title-wrapper">
                  <div class="popup-form-title">
                    <h3>{{ event.marketingPopupTitle }}</h3>
                  </div>
                  <div v-if="formStatusPopup != 'success'" class="pop-up-coursecode">
                    <span v-html="event.marketingPopupText"></span>
                  </div>
                </div>
                <form @submit.prevent="submitPopupDetails" v-if="formStatusPopup != 'success'" class="event-popup-form">
                  <div>
                    <label class="field-label">
                      <input class="registration-input-field" type="text" name="name" minlength="2" maxlength="20"
                        v-model="popupPerson.fullName" required>
                      <span class="registration-input-span">Full Name*</span>
                    </label>
                  </div>
                  <div>
                    <label class="field-label">
                      <input class="registration-input-field" type="text" name="email" minlength="2" maxlength="20"
                        v-model="popupPerson.email" required>
                      <span class="registration-input-span">Email*</span>
                    </label>
                  </div>
                  <div>
                    <label class="field-label">
                      <input class="registration-input-field" type="text" name="company" minlength="2" maxlength="20"
                        v-model="popupPerson.companyName" required>
                      <span class="registration-input-span">Company Name*</span>
                    </label>
                  </div>
                  <div class="popup-btn-wrapper">
                    <input type="button" value="Close" class="btn mt30px lowcase close" @click="closePopup" />
                    <input type="submit" value="Submit" class="btn mt30px lowcase" :disabled="formStatusPopup != ''">
                  </div>
                </form>
                <div class="event-tnx" v-if="formStatusPopup == 'success'">
                  <p><strong>Thank you!</strong></p>
                  <p>Please check your email account for further information</p>
                  <input type="button" value="Close" class="btn mt30px lowcase close" @click="closePopup" />
                </div>
              </div>
            </div>
          </div>



          <h1>{{ event.title }}</h1>
          <div class="event-date-location-box">
            <div class="">
              <p class="event-box-day">{{ new Date(event.date).getDate() }}</p>
              <p class="event-box-mon">
                {{
                  new Date(event.date).toLocaleString("en-US", {
                    month: "short",
                  })
                }}
              </p>
              <p class="event-box-year">
                {{ new Date(event.date).getFullYear() }}
              </p>
              <p v-if="event.time" class="event-box-time">
                {{ event.time }}
              </p>
            </div>

            <p>{{ event.textAddress }}</p>
          </div>
          <a class="btn event-signup-link" href="#event-signup">Signup to event</a>
          <SocialButtons :title="`${event.title} | ${event.blurb} | ${new Date(
            event.date
          ).toLocaleString('en-US', { month: 'short', day: '2-digit' })}`" :color="'white'" :size="20"
            class="social-share" />
          <div class="partners-event">
            <img v-for="partner in event.partners" :key="partner.imageURL" :src="partner.imageURL"
              :alt="partner.altText" />
          </div>

          <div v-html="event.details"></div>

          <p>{{ event.textAddress }}</p>
          <iframe v-if="event.location" :src="mapsEmbed" width="600" height="450" style="border: 0" allowfullscreen=""
            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="event-signup-ancor" id="event-signup"></div>
        <div class="event-signup">
          <div class="form-fields-wrapper">
            <form id="form" @submit.prevent="sendForm" v-if="
              formStatus != 'success' && !hasPassed && event.openForRegistration
            ">
              <p class="signup-title">Sign up here</p>
              <input placeholder="Full Name*" required="required" v-model="form.name" type="text" class="event-input" />

              <input placeholder="Job Title*" required="required" v-model="form.job" type="text" class="event-input" />
              <input placeholder="Company Name*" required="required" v-model="form.company" type="text" minlength="3"
                class="event-input" />

              <div>
                <div class="cloud-dropdown-container">
                  <multiselect class="multi-select" v-model="form.industry" :options="companies" :multiple="false"
                    :close-on-select="true" :append-to-body="false" :show-labels="false" :hide-selected="false"
                    :required="true" @remove="handleCloseCompanySelect" @input="handleInputCompanySelect"
                    placeholder="Select Company Type*">
                    <span class="noresults" slot="noResult">Oops, no company types found...</span>
                  </multiselect>
                </div>
                <div class="error-msg" v-if="showErrorMsgCompanyTypes">Please select a company type</div>

                <div class="cloud-dropdown-container">
                  <Multiselect class="multi-select" v-if="form.industry == 'Startup'" v-model="form.startUpType"
                    :options="startUp" :multiple="false" :close-on-select="true" :append-to-body="false"
                    :show-labels="false" :hide-selected="false" :required="true" @remove="handleCloseStartupSelect"
                    @input="handleInputStartupSelect" placeholder="Select Startup Stage*">
                    <span class="noresults" slot="noResult">Oops, no stages found...</span>
                  </multiselect>
                </div>
                <div class="error-msg"
                  v-if="showErrorMsgStartUpTypes && form.industry == 'Startup' && !this.form.startUpType">Please select
                  a
                  startup type</div>
              </div>

              <input placeholder="Email Address*" required="required" v-model="form.email" type="email"
                class="event-input" />
              <input placeholder="Phone Number*" v-model="form.phone" required type="tel" pattern="[0-9]+"
                title="Only numbers" class="event-input" />
              <input id="custom1" v-if="event.customFields.length > 0 && !custom1.availableOptions"
                v-model="form.custom1" :placeholder="custom1.fieldLabel" :required="custom1.isMandatory" type="text"
                class="event-input" />
              <div id="custom1">
                <div class="cloud-dropdown-container " v-if="event.customFields.length > 0 && custom1.availableOptions">
                  <Multiselect class="multi-select" v-model="form.custom1" :placeholder="custom1.fieldLabel"
                    :required="custom1.isMandatory" :multiple="custom1.multiSelectList"
                    :options="custom1.availableOptions.map(opt => opt.optionLabel)"
                    :close-on-select="!custom1.multiSelectList" :append-to-body="false" :show-labels="false"
                    :hide-selected="custom1.multiSelectList" @remove="handleClosecustom1Select"
                    @input="handleInputcustom1Select">
                  </multiselect>
                </div>
                <div class="error-msg"
                  v-if="event.customFields.length > 0 && custom1.isMandatory && showErrorMsgcustom1">
                  {{ custom1.validationErrorText ? custom1.validationErrorText : 'This field is required' }}</div>
              </div>

              <!-- temp patch for tech on wheels -->
              <div v-if="event._id!='66b8a565195c174a90375fcf'">
                <div class="cloud-dropdown-container">
                  <multiselect class="multi-select" v-model="selectedSectors"
                    :options="sectors.map(option => ({ value: option, text: option }))" :multiple="true"
                    :close-on-select="false" :append-to-body="false" :show-labels="false" :hide-selected="true"
                    placeholder="Select Sectors" track-by="value" label="text">
                    <span class="noresults" slot="noResult">Oops, no sectors found...</span>
                  </multiselect>
                </div>
                <input v-if="selectedSectors.map(option => option.value).includes('Other')"
                  placeholder="Enter Sector Name" v-model="form.otherSector" type="text" minlength="3" maxlength="20"
                  class="event-input" />
              </div>

              <!-- temp patch for tech on wheels -->
              <div v-if="event._id!='66b8a565195c174a90375fcf'">
                <div class="cloud-dropdown-container">
                  <multiselect class="multi-select" v-model="selectedClouds"
                    :options="clouds.map(option => ({ value: option, text: option }))" :multiple="true"
                    :close-on-select="false" :append-to-body="false" :show-labels="false" :hide-selected="true"
                    placeholder="Select Clouds" track-by="value" label="text">
                    <span class="noresults" slot="noResult">Oops, no clouds found...</span>
                  </multiselect>
                </div>
                <input v-if="selectedClouds.map(option => option.value).includes('Other')"
                  placeholder="Enter Provider Name" v-model="form.cloudProvider" type="text" minlength="3"
                  maxlength="20" class="event-input" />
              </div>

              <label class="bgl-checkbox-label">
                <input type="checkbox" class="bgl-checkbox" v-model="form.receiveEmail" />
                <span class="checkbox">✔</span>
                <span class="checkbox-txt">
                  I agree to receive marketing materials from Sela</span>
              </label>


              <input type="submit" value="Submit" :disabled="formStatus === 'sending'" class="btn event-btn lowcase" />
              <div class="error" v-if="formStatus == 'fail'">
                <p>An error occurred while submitting the form</p>
              </div>

            </form>
          </div>
          <div class="event-tnx" v-if="formStatus == 'success'">
            <p class="tnx-head">Thank you!</p>

            <div v-if="event.thankYouOnRegistration" v-html="event.thankYouOnRegistration"></div>
            <div v-else>
              <p>Your submission has been received!</p>

              <p><strong>Important:</strong> While your registration has been submitted, <strong>it's not yet
                  confirmed</strong>. Due to high demand, we may not be able to accommodate everyone.</p>
              <div class="atcb" ref="addToCal" style="display: none">
                {{ addToCalData }}
              </div>
            </div>
          </div>
          <div class="event-tnx" v-if="hasPassed || !event.openForRegistration">
            <div v-if="event.closedRegistrationMessage" v-html="event.closedRegistrationMessage"></div>
            <div v-else>
              Registration is now closed
              <p class="tnx-head">We'd love to see you in our next event</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <botfooter :country="country"></botfooter>
    <SocialMenu :vendor="``" :title="`${event.title}`" :color="'white'" :size="20" class="social-menu" />
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { atcb_init } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import SocialButtons from "../components/socialButtons.vue";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import countries from '../data/countries.json'; // https://github.com/michaelwittig/node-i18n-iso-countries/blob/master/langs/en.json  for updated countries list
import axios from 'axios';
import SocialMenu from "../components/SocialMenu.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country: String
  },
  components: {
    Topnav,
    Botfooter,
    SocialButtons,
    Multiselect,
    SocialMenu
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.event.metaTitle || this.event.title,
        description: this.event.metaDescription || this.event.blurb,
        image: this.event?.thumbnail?.imageURL || this.event.cover?.imageURL,
      }),
    };
  },
  data: () => ({
    isPopupOpen: false,
    formStatus: "",
    formStatusPopup: "",
    now: new Date(),
    showErrorMsgCompanyTypes: false,
    showErrorMsgStartUpTypes: false,
    showErrorMsgcustom1: false,
    form: {
      name: "",
      email: "",
      phone: "",
      company: "",
      job: "",
      receiveEmail: true,
      custom1: "",
      cloud: "",
      cloudProvider: "",
      sector: "",
      otherSector: "",
      industry: "",
      startUpType: "",
    },
    popupPerson: {
      fullName: "",
      email: "",
      companyName: "",
      eventId: ""
    },
    clouds: [
      'Google Cloud',
      'AWS',
      'Microsoft Azure',
      'Alibaba Cloud',
      'Other'
    ],
    selectedClouds: [],
    sectors: [
      "FinTech/Financial services",
      "Healthcare",
      "Information Technology (IT)",
      "Technology",
      "Cyber Security",
      "Agriculture",
      "Gaming",
      "Retail",
      "Utilities",
      "Other",
    ],
    selectedSectors: [],
    companies: [
      'Enterprise',
      'Government',
      'Hi-Tech',
      'Startup',
    ],
    startUp: [
      'Pre Seed',
      'Seed',
      'Startup',
    ],
  }),
  watch: {
    selectedClouds: {
      handler(newOptions) {
        const hasOtherOption = newOptions.some(opt => opt.value === 'Other');
        if (!hasOtherOption) {
          this.form.cloudProvider = ""; // Set the cloudProvider to an empty string
        }
        const selectedValues = newOptions.filter(opt => opt.value != 'Other').map(option => option.value);
        this.form.cloud = selectedValues.join(", ");
      },
      immediate: true
    },
    selectedSectors: {
      handler(newOptions) {
        const hasOtherOption = newOptions.some(opt => opt.value === 'Other');
        if (!hasOtherOption) {
          this.form.otherSector = ""; // Set the otherSector to an empty string
        }
        const selectedValues = newOptions.filter(opt => opt.value != 'Other').map(option => option.value);
        this.form.sector = selectedValues.join(", ");
      },
      immediate: true
    },
  },
  computed: {

    addToCalData() {
      return JSON.stringify({
        listStyle: "modal",
        branding: false,
        name: this.event.title,
        startDate: this.formatDate(this.event.date),
        endDate: this.formatDate(this.event.date),
        startTime: new Date(this.event.date).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        endTime: new Date(
          new Date(this.event.date).getTime() + 2 * 60 * 60 * 1000
        ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        location: this.event.location,
        options: [
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "MicrosoftTeams",
          "Outlook.com",
        ],
        iCalFileName: "Reminder-Event",
        description: this.event.blurb,
        timeZone: "Israel",
      });
    },
    event() {
      const { id } = this.$route.params;
      const event = this.$local.events?.find((c) => c.slug == id);
      return event || { cover: {}, partners: [] }
    },
    hasPassed() {

      return new Date(this.event.date) < new Date();
    },
    mapsEmbed() {
      const location = this.event.location;
      const url = `https://maps.google.com/maps?q=${location.lat},${location.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
      return encodeURI(url);
    },
    custom1() {
      const foundElement = this.event.customFields.find(field => field.fieldID === 'custom1');
      if (foundElement.predefinedContent && foundElement.predefinedContent.value == 'Countries') {
        foundElement.availableOptions = Object.values(countries.countriesList).map((value) => {
          if (Array.isArray(value)) {
            return { optionLabel: value[0] };
          }
          return { optionLabel: value };
        });
      }
      return foundElement || new Object();
    },
  },
  mounted() {
    this.startPopupTimer();

    if (this.$route.path.split("/").pop() === "thank-you")
      this.formStatus = "success";
    if (!this.event._id) {
      const { id } = this.$route.params;
      let event = this.$local.events?.find((c) => c._id == id);
      if (!event) this.$router.push(this.buildPath('/404'))
      else this.$router.push(this.buildPath(`/event/${event.slug}`));
    }
    this.$nextTick(() => {
      this.reorderFields();
    });
  },
  methods: {
    buildPath,
    startPopupTimer() {
      const showPopupAfterInSeconds = 20;

      setTimeout(() => {
        this.isPopupOpen = true;
      }, showPopupAfterInSeconds * 1000);
    },
    closePopup() {
      this.isPopupOpen = !this.isPopupOpen;
      return true;
    },
    reorderFields() {
      if (this.custom1.position) {
        var container = document.getElementById("form");
        const elementToMove = document.getElementById("custom1");
        elementToMove.remove();
        const newIndex = this.custom1.position;
        container.insertBefore(elementToMove, container.children[newIndex]);
      }
    },
    formatDate(d) {
      let yourDate = new Date(d);
      const offset = yourDate.getTimezoneOffset();
      yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
      return yourDate.toISOString().split("T")[0];
    },
    handleCloseCompanySelect() {
      if (!this.form.industry)
        this.showErrorMsgCompanyTypes = true;
    },
    handleInputCompanySelect() {
      if (this.form.industry)
        this.showErrorMsgCompanyTypes = false;
    },
    handleCloseStartupSelect() {
      if (this.form.industry == 'Startup' && !this.form.startUpType)
        this.showErrorMsgStartUpTypes = true;
    },
    handleInputStartupSelect() {
      if (this.form.industry == 'Startup' && this.form.startUpType)
        this.showErrorMsgStartUpTypes = false;
    },
    handleClosecustom1Select() {
      if (!this.form.custom1)
        this.showErrorMsgcustom1 = true;
    },
    handleInputcustom1Select() {
      if (this.form.custom1)
        this.showErrorMsgcustom1 = false;
    },
    async sendForm() {
      try {
        if (!this.form.industry) {
          this.showErrorMsgCompanyTypes = true;
          return;
        }
        if (this.form.industry == 'Startup' && !this.form.startUpType) {
          this.showErrorMsgStartUpTypes = true;
          return;
        }
        if (this.event.customFields.length > 0 && this.event.customFields[0].isMandatory && !this.form.custom1) {
          this.showErrorMsgcustom1 = true;
          return;
        }
        if (Array.isArray(this.form.custom1))
          this.form.custom1 = this.form.custom1.join(", ");

        this.formStatus = "sending";
        await this.$db
          .collection("events")
          .item(this.event._id)
          .collection("signUps")
          .post(this.form);
        this.formStatus = "success";
        this.$router.push(this.buildPath(`/event/${this.event.slug}/thank-you`));
        const triggerElement = this.$refs.addToCal;
        setTimeout(() => {
          atcb_init({
            triggerElement,
          });
        }, 100);
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
    async submitPopupDetails() {
      this.formStatusPopup = "sending";
      const requestData = this.popupPerson;
      requestData.eventId = this.event._id;

      console.log(requestData);
      try {
        await axios.post(
          '/.netlify/functions/submitPopupDetails',
          requestData
        );
        this.formStatusPopup = "success";

      } catch (error) {
        this.formStatusPopup = "fail";
        console.error(error);
      }
    },
  },
  name: "Event",
};
</script>

<style scoped>
.multi-select .multiselect__single {
  background-color: #41b883;
  color: #ffffff;
  width: auto;
  font-size: 14px;
  padding: 0px 5px 0px 5px;
}

.noresults {
  font-style: italic;
}

.cloud-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cloud-dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.cloud-dropdown-container ul {
  width: 100%;
  overflow-x: hidden;
}

select {
  border: none;
  height: 56px;
  margin: 4%;
  padding: 15px;
  border: 1px solid black;
  flex: 1;
  font-size: 16px;
}

.error-msg {
  font-size: small;
  color: red;
  margin-left: 5px;
  margin-top: -12px;
  margin-bottom: 5px;
}

.atcb-button {
  box-shadow: none;
  border-radius: 0;
  border: none;
  background-color: var(--black);
  color: white;
  width: 100%;
  font-family: "Lexend";
}

.atcb-button-wrapper {
  width: 100%;
  padding: none;
}

.atcb-button:hover {
  color: white;
  background-color: var(--black);
  filter: brightness(0.9);
}

#atcb-bgoverlay {
  backdrop-filter: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-form-title {
  border-color: var(--light-blue);
}

.event-txt .popup-form-title h3 {
  color: black;
}


.popup-wrapper {
  gap: 0;
}

.event-popup-form {
  width: 100%;
}

.form-title-wrapper,
.event-popup-form {
  max-width: 570px;
}

.popup-btn-wrapper {
  justify-content: center;
}

.field-label {
  width: 100%;
}
</style>
