<template>

  <div class="topnav-wrap" v-click-outside-element="closeNav" @mouseleave="closeNav">
    <div class="topnav">
      
      <router-link :to="buildPath('')" class="logo-wrap">
        <img src="../assets/sela-logo-tag.svg" alt="Sela. | Cloud Better." />
      </router-link>{{ country && country.substring(0,2) }}
      <button @click="openNav = !openNav" type="button" class="menu">
        <img src="../assets/menu.svg" alt="menu" />
      </button>
      <div class="nav-links-wrap" :class="{ opennav: openNav }">
        <button type="button" class="close-btn">
          <img src="../assets/black-x.svg" alt="Close" />
        </button>

        <div class="nav-btn-wrap" :class="{ navactive: tabNav === i + 1, [item.navClass]: true }"
          v-for="(item, i) in manu" :key="i">
          <button @click="handleClick(item)" :class="{
            'router-link-active': $route.path === item.route,
          }" @mouseover="tabNav = i + 1" type="button" class="nav-btn">

            {{ item.title }}
          </button>
          <!-- @mouseout="closeNav" -->
          <div class="drop-nav" v-if="tabNav !== 0">
            <div class="drop-nav-list" v-for="(sub, i) in item.sub" :key="i + 'sub'"
              :class="{ 'drop-nav-right': sub.subTitle }">
              <h3 class="nav-drop-title">{{ sub.title }}</h3>
              <p class="nav-drop-txt nomarg" v-if="sub.subTitle">
                {{ sub.subTitle }}
                <!--             <router-link v-if="sub.items[0].img" :to="sub.items[0].route">
                  <img class="nav-drop-img" :src="require(`@/assets/${sub.items[0].img}`)">
                </router-link> -->
              </p>
              <div class="nav-drop-items">

                <a v-for="subLink in sub.items" :key="subLink.title" :to="subLink.route" tag="a"
                  :href="buildPath(subLink.route)" :target="isAbsoluteUrl(subLink.route) ? '_blank' : '_self'"
                  rel="noopener noreferrer" class="nav-drop-item" @click="closeNavWrap()">

                  <img v-if="subLink.icon && selectIcon(subLink.icon)" :src="selectIcon(subLink.icon)"
                    :alt="subLink.title" />
                  <p class="nav-drop-small-title" :style="{ margin: subLink.icon ? '0 0 0 50px' : '0 0 0 0' }">
                    {{ subLink.menuItemLabel || subLink.title }}
                  </p>
                  <p class="nav-drop-txt">{{ subLink.subtitle }}</p>

                </a>


              </div>
            </div>
          </div>
        </div>
        <a class="customer-login closeOnHover" href="https://portal.selacloud.com/#/passport/login">
          <!-- <img height="20" src="../assets/icons/avatar.svg" /> -->
          Login</a>
        <div class="cont-nav">
          <router-link :to="buildPath('contact')" class="nav-link closeOnHover">Get Started</router-link>
        </div>
        <div class="bob-link">
          <router-link :to="buildPath('bobthebot')" title="Bob the Bot"><img src="../assets/bobMenuIcon.svg" /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country: String
  },
  data() {
    return {
      manu: [
        {
          title: "Solutions",
          route: "solutions",
          navClass: "yellow-line",
          sub: [
            {
              title: "Our Solutions",
              items: this.$local.solutions.map((solution) => ({
                ...solution,
                route: `solutions/${solution.slug}`,
              })),
            },
            {
              title: "Get Started",
              subTitle: `Unbiased multi-cloud solutions & expert advisory tailored for
                agile performance and cloud economics`,
              items: [
                {
                  title: "Get Started",
                  route: "contact",
                },
              ],
            },
          ],
        },
        {
          title: "Skilling",
          route: "skilling",
          navClass: "pink-line",
          sub: [
            {
              title: "Cloud(s) Skilling for Teams",
              items: this.$local.skilling[0].skillingList
                .filter(skill => !skill.menuOnly && skill.anchorId)
                .map((skill) => ({
                  ...skill,
                  route: `skilling#${skill.anchorId}`,
                })).concat(this.$local.skilling[0].skillingList
                  .filter(skill => skill.menuOnly)
                  .map((skill) => ({
                    ...skill,
                    route: `${skill.externalUrl}`,
                  }))),
            },
            {
              title: "Get Started",
              subTitle: `Learn more about our cloud skilling programs, and join the thousands who leap into the coveted cloud industry.`,
              items: [
                {
                  title: "Explore and Enroll",
                  route: "coursecatalog",
                },
              ],
            },
          ],
        },
        {
          title: "Delivery",
          route: "delivery",
          navClass: "blue-line",
          sub: [
            {
              title: "Our Consulting Services",
              items: [...this.$local.deliveryPages.map((deliveryPage) => ({
                ...deliveryPage,
                route: `delivery/${deliveryPage.slug}`,
              })), ...this.$local.deliveryPage.deliveryList.filter(deliveryItem => !deliveryItem.blueFrame).map((deliveryItem) => ({
                ...deliveryItem,
                route: `delivery#${deliveryItem.anchorID}`,
              }))],
            },
            {
              title: "Get Started",
              subTitle: `Learn more about our cloud skilling programs, and join the thousands who leap into the coveted cloud industry.`,
              items: [
                {
                  title: "Get Started",
                  route: "contact",
                },
              ],
            },
          ],

        },
        {
          title: "360 Support",
          route: "support",
          navClass: "blue-line",
          sub: [
            {
              title: "Support Services",
              items: this.$local.supportServices.map((service) => ({
                ...service,
                route: `support/${service.slug}`,
              })),
            },
            {
              title: "Get Started",
              subTitle: `Unbiased multi-cloud solutions & expert advisory tailored for
                agile performance and cloud economics`,
              items: [
                {
                  title: "Get Started",
                  route: "contact",
                },
              ],
            },
          ],
        },
        {
          title: "Partners",
          navClass: "yellow-line partnerswrap",
          sub: [
            {
              items: [
                {
                  title: "Microsoft Azure",
                  icon: "microsoft",
                  route: "azure",
                },
                {
                  title: "Google",
                  icon: "google",
                  route: "google-cloud",
                },
                {
                  title: "AWS",
                  icon: "aws",
                  route: "aws",
                },
                {
                  title: "Alibaba",
                  icon: "alibaba",
                  route: "alibaba",
                }
              ],
            },
          ],
        },
        {
          title: "Resources",
          navClass: "smallerwrap",
          sub: [
            {
              items: [
                {
                  title: "Blog",
                  icon: "blog",
                  route: "insights",
                },
                {
                  title: "Podcasts",
                  icon: "podcasts",
                  route: "podcasts",
                },
                {
                  title: "In The News",
                  icon: "news",
                  route: "in-the-news",
                },
                {
                  title: "Customer Stories",
                  icon: "customer2",
                  route: "customers",
                },
              ],
            },
          ],
        },        
        {
          title: "Events",
          route: "events",
          navClass: "green-line",
        },
        {
          title: "Company",
          navClass: "smallerwrap",
          sub: [
            {
              items: [
                {
                  title: "About Sela",
                  icon: "bulb",
                  route: "about",
                },
                {
                  title: "In The News",
                  icon: "news",
                  route: "in-the-news",
                },
                {
                  title: "International",
                  icon: "globe",
                  route: "international",
                },
                {
                  title: "Team",
                  icon: "customer",
                  route: "team",
                },
                {
                  title: "Careers",
                  icon: "suitecase",
                  route: "careers",
                }
              ],
            },
          ],
        },
      ],
      tabNav: 0,
      openNav: false,
    };
  },
  watch: {
    "$route.path"() {
      this.tabNav = 0;
    },
    "$route.hash"() {
      this.tabNav = 0;
    },
  },
  mounted() {
    this.listenToHover();
  },
  methods: {
    buildPath,
    handleClick(item) {
      const route = this.buildPath(item.route);
      if (item.route)
        this.$router.push(route);
    },
    selectIcon(iconName) {
      if (typeof iconName === "object") return iconName.imageURL;
      const icon = require.context("../assets/icons/");
      try {
        return icon(`./${iconName}.svg`);
      } catch (err) {
        return "";
      }
    },
    listenToHover() {
      document.querySelectorAll(".closeOnHover").forEach((e) => {
        e.addEventListener("mouseenter", () => {
          this.tabNav = 0;
        });
      });
    },
    closeNav() {
      setTimeout(() => {
        const el = document.querySelector(".drop-nav:hover");
        if (!el) this.tabNav = 0;
      }, 200);
    },
    closeNavWrap() {
      const el = document.querySelector(".opennav");
      el.classList = [...el.classList].filter(x => x != "opennav");
    },
    isAbsoluteUrl(route) {
      return /^https?:\/\//i.test(route);
    }
  },
  name: "topnav",
};
</script>
<!-- <style>
.nav-drop-img{
  margin: 10px;

}
.nav-drop-img:hover{
  filter: brightness(90%);
}
</style> -->